<template>
  <Teleport to="body">
    <div v-if="showing" class="toast toast-top toast-center z-50">
      <div v-if="type === 'info'" class="alert alert-info">
        <span>{{message}}</span>
      </div>
      <div v-if="type === 'success'" class="alert alert-success">
        <span>{{message}}</span>
      </div>
      <div v-if="type === 'warning'" class="alert alert-warning">
        <span>{{message}}</span>
      </div>
      <div v-if="type === 'error'" class="alert alert-error">
        <span>{{message}}</span>
      </div>
    </div>
  </Teleport>
</template>

<script>
import useSnackbar from './composables';
export default {
  setup () {
    const {
      message,
      showing,
      type,
    } = useSnackbar();

    return {
      message,
      showing,
      type,
    };
  },
};
</script>
